import React from 'react'
// Page types
import PageHome from './Pages/PageHome/PageHome'

// Global Modules
import Footer from './GlobalComponents/Footer/Footer'

// Modules

// Components
import ComponentNotFound from './ComponentNotFound'
import StoryblokImage from './Components/StoryblokImage/StoryblokImage'
import YoutubeVideo from './Components/YoutubeVideo/YoutubeVideo'
import StoryblokVideo from './Components/StoryblokVideo/StoryblokVideo'
import Content from './Components/Content/Content'
import List from './Modules/List/List'
import ImageWithText from './Modules/ImageWithText/ImageWithText'

// Modules
const ComponentList: any = {
  // Page types
  page: PageHome,

  // Global Components
  footer: Footer,

  // Modules
  list: List,
  image_with_text: ImageWithText,

  // Components
  image: StoryblokImage,
  youtube_video: YoutubeVideo,
  video: StoryblokVideo,
  content: Content,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
