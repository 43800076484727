import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './Footer.module.scss'
import { Props } from './types'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'

const Footer = ({ blok }: Props): JSX.Element => {
  const { links, copyright } = blok

  return (
    <SbEditable content={blok}>
      <Box as="footer" className={styles.wrapper}>
        <Flex
          minHeight={['61px', null, null, '76px']}
          justifyContent={['space-between']}
          alignItems={['center', null, null, 'center']}
          flexDirection={['row']}
        >
          <Flex
            flexWrap={'wrap'}
            justifyContent={['center', null, 'flex-start']}
            marginBottom={[0, null, null, 0]}
          >
            {links &&
              links?.length > 0 &&
              links.map((link: any) => {
                const { title, url } = getLinkAttributes(link)
                return (
                  <Box
                    key={`footer-link-${title}`}
                    marginRight={[3, null, null, 6]}
                    marginBottom={[0, null, 0]}
                    className={styles.link}
                  >
                    <LinkComponent to={url}>{title}</LinkComponent>
                  </Box>
                )
              })}
          </Flex>
          <Flex>
            {copyright && <Box className={styles.info}>{copyright}</Box>}
          </Flex>
        </Flex>
      </Box>
    </SbEditable>
  )
}

export default Footer
