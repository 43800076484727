import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'

import * as styles from './List.module.scss'
import classnames from 'classnames'
import SbEditable from 'storyblok-react'
import Components from 'src/storyblok/Components'
import Markdown from 'src/components/UI/Markdown/Markdown'
import { motion, AnimatePresence } from 'framer-motion'

const variants = {
  active: {
    height: '100%',
    // overflow: 'visible',
    // opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.2,
      bounce: 0,
    },
  },
  inactive: {
    height: '0',
    // overflow: 'hidden',
    // opacity: 0,
    transition: {
      type: 'tween',
      bounce: 0,
      duration: 0.2,
      // height: {
      //   delay: 0.1,
      // },
      // overflow: {
      //   delay: 0.3,
      // },
      // opacity: {
      //   duration: 0,
      // },
    },
  },
}

const Item = ({ blok, startOpen }: any) => {
  const [open, setOpen] = useState(startOpen)
  const { title, date, content } = blok

  return (
    <Flex
      marginBottom={[open ? 4 : 2, null, null, 4]}
      flexDirection="column"
      className={styles.item}
    >
      <Flex
        alignItems={'center'}
        paddingX={[7]}
        height={['62px', null, null, '82px']}
        paddingTop="2px"
        className={
          open ? [styles.button, styles.active].join(' ') : styles.button
        }
        onClick={() => {
          setOpen(!open)
        }}
        as="button"
      >
        <Box as="h3">{`${date} – ${title}`}</Box>
        <Box className={styles.plus}>
          <div />
          <div />
        </Box>
      </Flex>
      <motion.div
        initial="inactive"
        animate={open ? 'active' : 'inactive'}
        variants={variants}
      >
        {content && (
          <Box
            paddingBottom={[6, null, null, 8]}
            paddingX={[7]}
            className={classnames(styles.text)}
          >
            <Markdown>{content}</Markdown>
          </Box>
        )}
      </motion.div>
    </Flex>
  )
}

const List = ({ blok }: any) => {
  const { title, items, _uid } = blok

  return (
    <SbEditable content={blok}>
      <Flex flexDirection={'column'} key={_uid} width={1}>
        {title && (
          <Box className={styles.title} marginBottom={[2, null, null, 3]}>
            {title}
          </Box>
        )}
        {items &&
          items.map((item: any, index: number) => (
            <Item key={item._uid} blok={item} startOpen={index === 0} />
          ))}
      </Flex>
    </SbEditable>
  )
}

export default List
