import React from 'react'
import { Box } from 'reflexbox'

import * as styles from './ImageWithText.module.scss'
import classnames from 'classnames'
import Markdown from 'src/components/UI/Markdown/Markdown'
import SbEditable from 'storyblok-react'
import Components from 'src/storyblok/Components'
const ImageWithText = ({ blok }: any) => {
  const { text, title, _uid, media } = blok

  return (
    <SbEditable content={blok}>
      <Box key={_uid} width={1}>
        {media &&
          media?.length > 0 &&
          media.map((blok: any) => (
            <Box
              key={blok._uid}
              className={blok.component === 'image' ? styles.imageWrapper : ''}
              marginBottom={title || text ? [6, null, null, 8] : 0}
              width={1}
            >
              {React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })}
            </Box>
          ))}
        {title && (
          <Box className={styles.title} marginBottom={[3, null, null, 5]}>
            {title}
          </Box>
        )}
        {text && (
          <Box className={classnames(styles.text, styles.oneColumn)}>
            <Markdown>{text}</Markdown>
          </Box>
        )}
      </Box>
    </SbEditable>
  )
}

export default ImageWithText
