// extracted by mini-css-extract-plugin
export var wrapper = "PageHome-module--wrapper--39De0";
export var section = "PageHome-module--section--3wTbc";
export var anchor = "PageHome-module--anchor--12BkE";
export var hero = "PageHome-module--hero--1Cuub";
export var inner = "PageHome-module--inner--3eYWj";
export var logo = "PageHome-module--logo--1-fx8";
export var label = "PageHome-module--label--1Cc1c";
export var dateTitle = "PageHome-module--dateTitle--R9xc3";
export var title = "PageHome-module--title--2BCuR";
export var flowers = "PageHome-module--flowers--hfWJ-";
export var sponsors = "PageHome-module--sponsors--1APM2";
export var badge = "PageHome-module--badge--364nY";
export var spin = "PageHome-module--spin--3_bZ3";
export var right = "PageHome-module--right--2NgR-";
export var nav = "PageHome-module--nav--2Aq2i";
export var navItem = "PageHome-module--navItem--C-7Kz";