import React from 'react'
import SbEditable from 'storyblok-react'

import * as styles from './PageHome.module.scss'
import SEOPage from 'src/components/SEOPage/SEOPage'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'
import Footer from 'src/storyblok/GlobalComponents/Footer/Footer'
import { stringify } from 'src/helpers/stringify'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const PageHome = ({ blok, full_slug, footer }: any): JSX.Element => {
  const {
    seo_data,
    logotype,
    location_text,
    date_text,
    lineup_items,
    time_text,
    flowers,
    body,
    sponsors,
    badge_title,
  } = blok

  return (
    <SbEditable content={blok}>
      <SEOPage path={full_slug} storyblokData={seo_data} />
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        width={1}
      >
        <Box
          paddingX={[4, null, null, 8]}
          paddingTop={['16%', null, null, '108px']}
          paddingBottom={[5, null, null, 8]}
          width={[1, null, null, 1 / 2]}
          className={styles.hero}
        >
          <Flex
            className={styles.inner}
            height={'100%'}
            width={1}
            flexDirection={['column']}
            alignItems="center"
          >
            {logotype?.filename && (
              <Image
                className={styles.logo}
                src={logotype.filename}
                alt={'Förfesten'}
              />
            )}
            {location_text && (
              <SbEditable content={blok}>
                <Box className={styles.label} as="p">
                  {location_text}
                </Box>
              </SbEditable>
            )}
            {date_text && (
              <SbEditable content={blok}>
                <Box
                  marginBottom={[0, null, null, 13]}
                  className={styles.label}
                  as="p"
                >
                  {date_text}
                </Box>
              </SbEditable>
            )}
            <Flex
              marginY={['auto', null, null, 0]}
              alignItems={'center'}
              flexDirection="column"
              className={styles.centerContent}
            >
              <Flex
                textAlign={'center'}
                alignItems="center"
                justifyContent={'center'}
                flexWrap="wrap"
                marginBottom={2}
                paddingX={[8]}
              >
                {lineup_items &&
                  lineup_items.map((item: any) => (
                    <SbEditable content={item}>
                      <Box
                        as="span"
                        marginX={[1, null, null, 3]}
                        className={styles.title}
                        key={item.id}
                      >
                        {item.title}
                      </Box>
                    </SbEditable>
                  ))}
              </Flex>
              {time_text && (
                <SbEditable content={blok}>
                  <Box
                    paddingTop={3}
                    as="p"
                    className={[styles.label, styles.dateTitle].join(' ')}
                  >
                    {time_text}
                  </Box>
                </SbEditable>
              )}
            </Flex>
            <Box height={['48px']} />
            {badge_title && (
              <Flex
                alignItems={'center'}
                justifyContent="center"
                className={styles.badge}
              >
                {badge_title}
              </Flex>
            )}
            <Flex
              alignItems={'center'}
              paddingBottom={[4, null, null, 8]}
              className={styles.sponsors}
            >
              {sponsors &&
                sponsors?.length > 0 &&
                sponsors.map((blok: any, index: number) => (
                  <Box marginX={[2]} key={blok._uid} className={styles.sponsor}>
                    <SbEditable content={blok}>
                      {React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })}
                    </SbEditable>
                  </Box>
                ))}
            </Flex>
            {flowers?.filename && (
              <Image
                className={styles.flowers}
                src={flowers.filename}
                alt={'flowers'}
              />
            )}
          </Flex>
        </Box>
        <Flex
          paddingX={[4, null, null, 8]}
          paddingTop={[5, null, null, 8]}
          width={[1, null, null, 1 / 2]}
          flexDirection="column"
          className={styles.right}
        >
          <Flex marginBottom={[6]} className={styles.nav}>
            {body &&
              body?.length > 0 &&
              body
                .filter(
                  (blok: any) =>
                    blok.title && blok.component !== 'image_with_text'
                )
                .map((blok: any) => (
                  <LinkComponent
                    className={styles.navItem}
                    to={`/#${stringify(blok.title)}`}
                    key={`button-${blok._uid}`}
                  >
                    {blok.title}
                  </LinkComponent>
                ))}
          </Flex>
          {body &&
            body?.length > 0 &&
            body.map((blok: any, index: number) => (
              <Box
                key={blok._uid}
                className={styles.section}
                paddingBottom={[10, null, null, 16]}
                marginBottom={
                  index === body.length - 1 ? 0 : [10, null, null, 16]
                }
              >
                <div className={styles.anchor} id={stringify(blok.title)} />
                {React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                })}
              </Box>
            ))}
          {footer?.content && <Footer blok={footer.content} />}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default PageHome
