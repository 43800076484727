import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import type { Props } from './types'

const locales = ['sv', 'en']

const SEO = ({
  bodyClass,
  canonical,
  children,
  lang = 'sv',
  noIndex = false,
  description,
  image,
  title,
  type,
  asPath,
}: Props): JSX.Element => {
  const { locale } = useIntl()
  const props = {
    bodyAttributes: {
      class: bodyClass || '',
    },
    htmlAttributes: {
      lang: locale,
    },
    title: title,
  }

  if (image && image.startsWith('//')) {
    image = `https:${image}`
  }
  const siteUrl = 'https://forfesten.com/'
  return (
    <Helmet defer={false} {...props}>
      <meta
        name="facebook-domain-verification"
        content="9u2vc4yp59zi0sbmsb9qb1bwbva1id"
      />
      {siteUrl && (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${siteUrl}${asPath}`}
        />
      )}
      {locales &&
        locales.length > 0 &&
        locales.map((loc: string) => {
          return (
            <link
              key={`locale-${loc}`}
              rel="alternate"
              hrefLang={loc}
              href={`${siteUrl}${loc === 'sv' ? '' : `${loc}/`}${asPath}`}
            />
          )
        })}
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {title && <meta property="og:image:alt" content={title} />}
      {title && <meta property="twitter:image:alt" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="twitter:image:src" content={image} />}
      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}
      {children}
    </Helmet>
  )
}

export default SEO
